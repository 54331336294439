import React from "react";

import {
  LoadingContainer,
  navigateTo,
} from "@interactive-investor/onestack-web-shared";

import { withAuth } from "../../contexts/Auth";
import { AuthContextInjectedProps } from "../../contexts/Auth/interfaces/context";

class AuthPage extends React.Component<AuthContextInjectedProps> {
  componentDidMount() {
    if (this.props.auth.isAuthenticatedFree()) {
      navigateTo("/");
    } else {
      this.props.auth.loginFree();
    }
  }

  render() {
    return <LoadingContainer title="Authentication" message="Please wait..." />;
  }
}

export default withAuth(AuthPage);
